import { EPlayerType } from '@/lib/DBModels';

export type TMutatePromotionBody = {
  name: string;
  reason?: string;
  promo_type: TPromoType;
  start_time?: string;
  expiry?: string;
  punter_id?: string;
  parameters: TParams;
  race_ids?: string[];
  audience_conditions?: TAudience;
  promo_description?: string;
  promo_id?: string;
};

export enum AgeRange {
  All = 'all',
  YoungAdult = 'young_adult',
  PrimeAdult = 'prime_adult',
  MatureAdult = 'mature_adult',
  SeniorAdult = 'senior_adult',
}

export type TAudience = {
  punter_categories?: EPlayerType[] | null;
  age_ranges?: AgeRange[] | null;
  address_locations?: string[] | null;
  affiliates: string[] | null;
  min_balance: number | null;
  max_balance: number | null;
  min_ebc: number | null;
  max_ebc: number | null;
  max_last_bet_days_ago: number | null;
  include_new_punters: boolean | null;
  min_active_runners: number | null;
};

export type TParams = {
  percentage?: number;
  max_amount?: number | string | null;
  number_of_races?: number;
  max_payout_position?: number | null;
};

export type TPromoType =
  | 'deposit_match'
  | 'first_deposit_match'
  | 'bonus_bet'
  | 'manual_credit'
  | 'money_back'
  | 'treasure_hunt';

export type TMutateCancelPromotionParams = {
  promo_id: string;
  punter_id: string;
};

export type TQueryPromotionParams = {
  promo_id: string;
};

export type TMutateEditPromotionParams = {
  promo_id?: string;
  name?: string;
  promo_description?: string;
};

export type TQueryPromotionResponse = TMutatePromotionBody;
