import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Skeleton,
  Switch,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Card, TabNavLink } from '@/common/components';
import {
  useMutationOfferings,
  useQueryOfferings,
} from '@/api/settings/system/offerings/offerings.hooks';
import useErrorHandler from '@/hooks/useErrorHandler';
import { isSweeps } from '@/configs/brands.config';

type TNavLinks = {
  to: string;
  children: JSX.Element;
  isHidden?: boolean;
};

const bonusBetsTabName = isSweeps
  ? 'promomanagerpage.giveAway'
  : 'promomanagerpage.bonusbets';

const NAV_LINKS: TNavLinks[] = [
  {
    to: '/promo-manager',
    children: <FormattedMessage id={bonusBetsTabName} />,
  },
  {
    to: '/promo-manager/deposit-match',
    children: <FormattedMessage id="promomanagerpage.depositmatch" />,
    isHidden: isSweeps,
  },
  {
    to: '/promo-manager/banners',
    children: <FormattedMessage id="promomanagerpage.tabs.banners" />,
  },
  {
    to: '/promo-manager/promotions',
    children: <FormattedMessage id="promomanagerpage.tabs.promotions" />,
  },
  {
    to: '/promo-manager/affiliates',
    children: <FormattedMessage id="promomanagerpage.tabs.affiliates" />,
  },
  {
    to: '/promo-manager/money-back',
    children: <FormattedMessage id="promomanagerpage.moneyBack" />,
    isHidden: isSweeps,
  },
  {
    to: '/promo-manager/odds-boost',
    children: <FormattedMessage id="promomanagerpage.oddsBoost" />,
    isHidden: isSweeps,
  },
  {
    to: '/promo-manager/treasure-hunt',
    children: <FormattedMessage id="promomanagerpage.treasureHunt" />,
    isHidden: isSweeps,
  },
];

export const PromoManagerWrapper = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const onError = useErrorHandler();
  const mutation = useMutationOfferings();
  const query = useQueryOfferings({ refetchOnWindowFocus: false });
  const toast = useToast();
  const { data, isLoading, refetch } = query;

  const handleMoneyBackToggle = (value: boolean) => {
    mutation.mutate(
      {
        ...(query.data?.data || {}),
        moneyback_enabled: value,
      },
      {
        onError,
        onSuccess: async () => {
          await refetch();
          toast({
            title: 'Successfully updated!',
            status: 'success',
            position: 'top',
            duration: 9_000,
          });
        },
      }
    );
  };

  const handleTreasureHuntToggle = (value: boolean) => {
    mutation.mutate(
      {
        ...(query.data?.data || {}),
        treasure_hunt_enabled: value,
      },
      {
        onError,
        onSuccess: async () => {
          await refetch();
          toast({
            title: 'Successfully updated!',
            status: 'success',
            position: 'top',
            duration: 9_000,
          });
        },
      }
    );
  };

  const isBannersTab = pathname.includes('banners');
  const isPromotionsTab = pathname.includes('promotions');
  const isAffiliatesTab = pathname.includes('affiliates');
  const isMoneyBackTab = pathname.includes('money-back');
  const isTreasureHuntTab = pathname.includes('treasure-hunt');

  const getMessageId = () => {
    if (isBannersTab) return 'promomanagerpage.addBanner';
    if (isPromotionsTab) return 'promomanagerpage.addPromotions';
    if (isAffiliatesTab) return 'promomanagerpage.createAffiliate';
    if (isMoneyBackTab) return 'promomanagerpage.addPromotion';
    if (isTreasureHuntTab) return 'promomanagerpage.addPromotion';
    return 'promomanagerpage.importcsv';
  };

  return (
    <Card title={formatMessage({ id: 'promomanagerpage.title' })}>
      <Flex
        alignItems="center"
        justifyContent="space-between"
        borderBottom="1px"
        borderColor="gray.100"
      >
        <Flex>
          {NAV_LINKS.filter((link) => !link.isHidden).map((props, index) => {
            const { to } = props;
            return (
              <TabNavLink
                key={`${index}-${to}`}
                isActive={to === pathname}
                {...props}
              />
            );
          })}
        </Flex>

        <Flex>
          {isMoneyBackTab && (
            <FormControl
              w="200px"
              ml="auto"
              mr="3"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              {isLoading ? (
                <Skeleton height="20px" />
              ) : (
                <>
                  <FormLabel htmlFor="money-back" mb="0">
                    Money Back Enabled
                  </FormLabel>

                  <Switch
                    id="money-back"
                    defaultChecked={!!data?.data.moneyback_enabled ?? false}
                    onChange={(e) => handleMoneyBackToggle(e.target.checked)}
                  />
                </>
              )}
            </FormControl>
          )}

          {isTreasureHuntTab && (
            <FormControl
              w="200px"
              ml="auto"
              mr="3"
              display="flex"
              alignItems="center"
              justifyContent="end"
            >
              {isLoading ? (
                <Skeleton height="20px" />
              ) : (
                <>
                  <FormLabel htmlFor="treasure-hunt" mb="0">
                    Treasure Hunt Enabled
                  </FormLabel>

                  <Switch
                    id="treasure-hunt"
                    defaultChecked={!!data?.data.treasure_hunt_enabled ?? false}
                    onChange={(e) => handleTreasureHuntToggle(e.target.checked)}
                  />
                </>
              )}
            </FormControl>
          )}

          <Button
            ml="auto"
            mr="5"
            minW="12"
            onClick={() => {
              if (isPromotionsTab)
                return navigate('/promo-manager/promotion/add-new');
              if (isBannersTab) navigate('/promo-manager/banner/add-banner');
              if (isMoneyBackTab)
                navigate('/promo-manager/money-back/add-promotion');
              if (isTreasureHuntTab)
                navigate('/promo-manager/treasure-hunt/add-promotion');
              return onOpen();
            }}
          >
            <FormattedMessage id={getMessageId()} />
          </Button>
        </Flex>
      </Flex>

      <Outlet context={{ isOpen, onOpen, onClose }} />
    </Card>
  );
};
