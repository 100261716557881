import { FormattedMessage, useIntl } from 'react-intl';
import DropzonePreview from '@/common/components/Dropzone/DropzonePreview';
import {
  Button,
  Checkbox,
  Flex,
  Radio,
  Select,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AU_STATES } from '@/lib/Constants';
import BCCheckbox from '../Checkbox/BCCheckbox';
import { ErrorMessage, useFormikContext } from 'formik';
import { TFormikValues } from './FormHOC';
import Input from '@/common/components/FormElements/Input';
import { BCGridItem, BCGridWrapper } from '../../styles/Banner.styles';
import {
  FlexDropZoneBody,
  FlexDrozoneWrapper,
  RadioButtonWrapper,
  TextError,
} from './Form.styles';
import { useBanner } from '../../services/Banner.hooks';
import { usePunterCategoriesObject } from './Form.hooks';
import {
  EDisplay,
  EStatus,
  TData,
} from '@/api/promoManager/banners/banner.types';
import { useNavigate } from 'react-router-dom';
import DateTimePicker from '@/common/components/FormElements/DateTimePicker/DateTimePicker';
import dayjs from 'dayjs';
import { queryPromoSummary } from '@/api/promoSummary/promoSummary';
import { useQuery } from 'react-query';
import { isSweeps } from '@/configs/brands.config';

type TFormData = {
  banner?: TData;
};

export default function Form({ banner }: TFormData) {
  const { values, setFieldValue, dirty, isSubmitting, errors } =
    useFormikContext<TFormikValues>();
  const { handleDelete, isLoading } = useBanner({ id: banner?.id });
  const punterCategoryList = usePunterCategoriesObject();
  const intl = useIntl();
  const navigate = useNavigate();

  const { data: moneyBackPromotions } = useQuery(
    ['moneyBackPromoSummary', values.has_money_back_promo],
    () =>
      queryPromoSummary({
        promo_type: 'money_back',
        limit: 100,
        sources: 'manual',
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!values.has_money_back_promo,
    }
  );
  const { data: treasureHuntPromotions } = useQuery(
    ['treasureHuntPromoSummary', values.has_treasure_hunt_promo],
    () =>
      queryPromoSummary({
        promo_type: 'treasure_hunt',
        limit: 100,
        sources: 'manual',
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!values.has_treasure_hunt_promo,
    }
  );

  if (isLoading) return;

  return (
    <BCGridWrapper>
      <BCGridItem area={'title'}>
        <Text>
          {intl.formatMessage({ id: 'promomanagerpage.banner.title' })}
        </Text>
        <Input
          type="text"
          name="title"
          value={values.title}
          defaultValue={banner?.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('title', e.target.value)
          }
          placeholder={intl.formatMessage({
            id: 'promomanagerpage.banner.titleInput.placeholder',
          })}
        />
      </BCGridItem>
      <BCGridItem area={'main'}>
        {values.platform !== 'app' && (
          <>
            <TextError>
              <ErrorMessage
                name="genericError"
                component="div"
                className="error-message"
              />
            </TextError>

            <Flex justifyContent="space-between" w="full">
              <Text>
                {intl.formatMessage({
                  id: 'promomanagerpage.banner.webDesktop',
                })}
              </Text>

              <Flex>
                <Text mr="3">
                  {intl.formatMessage({
                    id: 'promomanagerpage.banner.2LayerUpload',
                  })}
                </Text>
                <Switch
                  name="media.desktop.isTwoLayer"
                  defaultChecked={!!banner?.media.desktop?.layerLink}
                  onChange={() =>
                    setFieldValue(
                      'media.desktop.isTwoLayer',
                      !values.media.desktop?.isTwoLayer
                    )
                  }
                />
              </Flex>
            </Flex>

            <TextError>
              <ErrorMessage
                name="media.desktop.base64"
                component="div"
                className="error-message"
              />
            </TextError>
            <FlexDrozoneWrapper>
              <Flex flexDir="column" w="full">
                {(values.media.desktop?.isTwoLayer ||
                  banner?.media.desktop?.layerLink) && (
                  <Text>
                    {intl.formatMessage({
                      id: 'promomanagerpage.banner.backImage',
                    })}
                  </Text>
                )}
                <DropzonePreview
                  file={values?.media?.desktop?.publicUrl ?? ''}
                  onFilesSelected={(e) => {
                    setFieldValue('media.desktop.publicUrl', e);
                  }}
                  getName={(e) => setFieldValue('media.desktop.name', e)}
                  placeholder={intl.formatMessage({
                    id: 'promomanagerpage.banner.imageUplaoder.placeholder',
                  })}
                  name="media.desktop.base64"
                />
              </Flex>

              {(values.media.desktop?.isTwoLayer ||
                banner?.media?.desktop?.layerLink) && (
                <FlexDropZoneBody>
                  <Text>
                    {intl.formatMessage({
                      id: 'promomanagerpage.banner.frontImage',
                    })}
                  </Text>
                  <DropzonePreview
                    file={values?.media?.desktop?.animatedLayerBase64 ?? ''}
                    onFilesSelected={(e) =>
                      setFieldValue('media.desktop.animatedLayerBase64', e)
                    }
                    getName={(e) => setFieldValue('media.desktop.layerName', e)}
                    placeholder={intl.formatMessage({
                      id: 'promomanagerpage.banner.imageUplaoder.placeholder',
                    })}
                    name="media.desktop.base64"
                  />
                </FlexDropZoneBody>
              )}
            </FlexDrozoneWrapper>

            <Flex justifyContent="space-between" w="full">
              <Text>
                {intl.formatMessage({
                  id: 'promomanagerpage.banner.webMobile',
                })}
              </Text>

              <Flex>
                <Text mr="3">
                  {intl.formatMessage({
                    id: 'promomanagerpage.banner.2LayerUpload',
                  })}
                </Text>
                <Switch
                  name="media.mobile.isTwoLayer"
                  defaultChecked={!!banner?.media.mobile?.layerLink}
                  onChange={() =>
                    setFieldValue(
                      'media.mobile.isTwoLayer',
                      !values.media.mobile?.isTwoLayer
                    )
                  }
                />
              </Flex>
            </Flex>
            <TextError>
              <ErrorMessage
                name="media.mobile.base64"
                component="div"
                className="error-message"
              />
            </TextError>

            <FlexDrozoneWrapper>
              <FlexDropZoneBody>
                {values.media.mobile?.isTwoLayer && (
                  <Text>
                    {intl.formatMessage({
                      id: 'promomanagerpage.banner.backImage',
                    })}
                  </Text>
                )}

                <DropzonePreview
                  name="media.mobile.base64"
                  file={values?.media?.mobile?.publicUrl ?? ''}
                  getName={(e) => setFieldValue('media.mobile.name', e)}
                  onFilesSelected={(e) => {
                    setFieldValue('media.mobile.publicUrl', e);
                  }}
                  placeholder={intl.formatMessage({
                    id: 'promomanagerpage.banner.imageUplaoder.placeholder',
                  })}
                />
              </FlexDropZoneBody>

              {(values.media.mobile?.isTwoLayer ||
                banner?.media?.mobile?.layerLink) && (
                <FlexDropZoneBody>
                  <Text>
                    {intl.formatMessage({
                      id: 'promomanagerpage.banner.frontImage',
                    })}
                  </Text>

                  <DropzonePreview
                    name="media.mobile.base64"
                    file={values?.media?.mobile?.animatedLayerBase64 ?? ''}
                    getName={(e) => setFieldValue('media.mobile.layerName', e)}
                    onFilesSelected={(e) =>
                      setFieldValue('media.mobile.animatedLayerBase64', e)
                    }
                    placeholder={intl.formatMessage({
                      id: 'promomanagerpage.banner.imageUplaoder.placeholder',
                    })}
                  />
                </FlexDropZoneBody>
              )}
            </FlexDrozoneWrapper>
          </>
        )}

        {values.platform !== 'web' && (
          <>
            <Flex justifyContent="space-between" w="full">
              <Text>
                {intl.formatMessage({ id: 'promomanagerpage.banner.app' })}
              </Text>

              <Flex>
                <Text mr="3">
                  {intl.formatMessage({
                    id: 'promomanagerpage.banner.2LayerUpload',
                  })}
                </Text>
                <Switch
                  name="media.app.isTwoLayer"
                  defaultChecked={!!banner?.media.app?.layerLink}
                  onChange={() =>
                    setFieldValue(
                      'media.app.isTwoLayer',
                      !values.media.app?.isTwoLayer
                    )
                  }
                />
              </Flex>
            </Flex>

            <TextError>
              <ErrorMessage
                name="media.app.base64"
                component="div"
                className="error-message"
              />
            </TextError>
            <FlexDrozoneWrapper>
              <Flex flexDir="column" w="full">
                {(values.media.app?.isTwoLayer ||
                  banner?.media.app?.layerLink) && (
                  <Text>
                    {intl.formatMessage({
                      id: 'promomanagerpage.banner.backImage',
                    })}
                  </Text>
                )}
                <DropzonePreview
                  file={values?.media?.app?.publicUrl ?? ''}
                  onFilesSelected={(e) => {
                    setFieldValue('media.app.publicUrl', e);
                  }}
                  getName={(e) => setFieldValue('media.app.name', e)}
                  placeholder={intl.formatMessage({
                    id: 'promomanagerpage.banner.imageUplaoder.placeholder',
                  })}
                  name="media.app.base64"
                />
              </Flex>

              {(values.media.app?.isTwoLayer ||
                banner?.media?.app?.layerLink) && (
                <FlexDropZoneBody>
                  <Text>
                    {intl.formatMessage({
                      id: 'promomanagerpage.banner.frontImage',
                    })}
                  </Text>
                  <DropzonePreview
                    file={values?.media?.app?.animatedLayerBase64 ?? ''}
                    onFilesSelected={(e) =>
                      setFieldValue('media.app.animatedLayerBase64', e)
                    }
                    getName={(e) => setFieldValue('media.app.layerName', e)}
                    placeholder={intl.formatMessage({
                      id: 'promomanagerpage.banner.imageUplaoder.placeholder',
                    })}
                    name="media.app.base64"
                  />
                </FlexDropZoneBody>
              )}
            </FlexDrozoneWrapper>
          </>
        )}

        <Input
          type="text"
          name="webLink"
          label="Link"
          value={values.media.desktop?.link}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setFieldValue('media.desktop.link', e.target.value)
          }
          placeholder={intl.formatMessage({
            id: 'promomanagerpage.banner.linkInput.placeholder',
          })}
          sx={{ mb: '7', w: '50%' }}
        />

        {/* Disabled for MVP */}
        {/*
        <Text>{intl.formatMessage({ id: 'promomanagerpage.banner.app' })}</Text>
        <TextError>
          <ErrorMessage
            name="media.app.base64"
            component="div"
            className="error-message"
          />
        </TextError>


        <DropzonePreview
          file={
            (banner?.media?.app?.publicUrl || values?.media.app?.base64) ?? ''
          }
          onFilesSelected={(e) => setFieldValue('media.app.base64', e)}
          getName={(e) => setFieldValue('media.app.name', e)}
          placeholder={intl.formatMessage({
            id: 'promomanagerpage.banner.imageUplaoder.placeholder',
          })}
          name="media.app.base64"
        /> */}
      </BCGridItem>
      <BCGridItem area={'nav'} bg="transparent">
        <Flex sx={{ gap: '3', justifyContent: 'space-between' }}>
          {banner && (
            <Button
              colorScheme="red"
              variant="outline"
              onClick={handleDelete}
              w="full"
            >
              {intl.formatMessage({ id: 'promomanagerpage.banner.discard' })}
            </Button>
          )}

          {!banner && (
            <Button type="submit" sx={{ bg: 'origin.500', w: 'full' }}>
              {intl.formatMessage({ id: 'promomanagerpage.banner.save' })}
            </Button>
          )}
        </Flex>
        <Text mt="5">
          {intl.formatMessage({ id: 'promomanagerpage.banner.status' })}
        </Text>
        <RadioButtonWrapper
          onChange={(e: string) => setFieldValue('status', e)}
          defaultValue={(banner?.status || values.status) ?? EStatus.Draft}
          name="status"
        >
          <VStack align="flex-start" spacing={2} sx={{ fontSize: 'sm' }}>
            <Radio value={EStatus.Active}>{EStatus.Active}</Radio>
            <Radio value={EStatus.Draft}>{EStatus.Draft}</Radio>
            <Radio value={EStatus.Scheduled}>{EStatus.Scheduled}</Radio>
          </VStack>
        </RadioButtonWrapper>
        <Text mt="5">Platform</Text>
        <RadioButtonWrapper
          onChange={(e: string) => setFieldValue('platform', e)}
          isDisabled={!!banner}
          defaultValue={(banner?.platform || values.platform) ?? 'web'}
          name="status"
        >
          <VStack align="flex-start" spacing={2} sx={{ fontSize: 'sm' }}>
            <Radio value="web">Web</Radio>
            <Radio value="app">App</Radio>
            <Radio value="both">Both</Radio>
          </VStack>
        </RadioButtonWrapper>

        {values.status === EStatus.Scheduled && (
          <>
            <FormattedMessage id="promomanagerpage.banner.startTime" />
            <DateTimePicker
              disableClock
              minDate={
                banner?.start_date ? new Date(banner.start_date) : new Date()
              }
              name="start_date"
              value={
                values.start_date ? new Date(values.start_date) : undefined
              }
              wrapperProps={{ h: '10' }}
              onChange={(value) =>
                setFieldValue(
                  'start_date',
                  value === null
                    ? undefined
                    : dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
                )
              }
            />
            {errors.start_date && <TextError>{errors.start_date}</TextError>}
            <FormattedMessage id="promomanagerpage.banner.endTime" />
            <DateTimePicker
              disableClock
              minDate={new Date()}
              name="end_date"
              value={values.end_date ? new Date(values.end_date) : undefined}
              wrapperProps={{ h: '10' }}
              onChange={(value) =>
                setFieldValue(
                  'end_date',
                  value === null
                    ? undefined
                    : dayjs(value).format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
                )
              }
            />
            {errors.end_date && <TextError>{errors.end_date}</TextError>}
          </>
        )}

        {!values.has_money_back_promo && !values.has_treasure_hunt_promo && (
          <>
            <Text mt="5">
              {intl.formatMessage({
                id: 'promomanagerpage.banner.bannerDisplay',
              })}
            </Text>
            <RadioButtonWrapper
              onChange={(e: string) => setFieldValue('display', e)}
              defaultValue={
                (banner?.display || values.display) ?? EDisplay.LoggedIn
              }
              name="display"
            >
              <VStack align="flex-start" spacing={2} sx={{ fontSize: 'sm' }}>
                <Radio value={EDisplay.LoggedIn}>{EDisplay.LoggedIn}</Radio>
                <Radio value={EDisplay.LoggedOut}>{EDisplay.LoggedOut}</Radio>
                <Radio value={EDisplay.Both}>{EDisplay.Both}</Radio>
              </VStack>
            </RadioButtonWrapper>
          </>
        )}

        {!isSweeps && (
          <Checkbox
            size="md"
            colorScheme="blue"
            defaultChecked={!!values.has_money_back_promo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('has_money_back_promo', e.target.checked);
              setFieldValue('display', EDisplay.Both);
            }}
          >
            Money Back Promotion
          </Checkbox>
        )}
        {(banner?.has_money_back_promo || !!values.has_money_back_promo) &&
          !isSweeps && (
            <Select
              mt="3"
              placeholder="Select a Promo"
              defaultValue={
                !!banner
                  ? banner.money_back_promo_id
                  : values.money_back_promo_id
              }
              value={
                !!values.money_back_promo_id
                  ? values.money_back_promo_id
                  : banner?.money_back_promo_id
              }
              onChange={(e) =>
                setFieldValue('money_back_promo_id', e.target.value)
              }
            >
              {moneyBackPromotions?.data.promotions.map((promotion) => (
                <option key={promotion.promo_id} value={promotion.promo_id}>
                  {promotion.name}
                </option>
              ))}

              {/* Add more options as needed */}
            </Select>
          )}

        {!isSweeps && (
          <Checkbox
            size="md"
            colorScheme="blue"
            defaultChecked={!!values.has_treasure_hunt_promo}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFieldValue('has_treasure_hunt_promo', e.target.checked);
              setFieldValue('display', EDisplay.Both);
            }}
          >
            Treasure Hunt Promotion
          </Checkbox>
        )}
        {(banner?.has_treasure_hunt_promo ||
          !!values.has_treasure_hunt_promo) &&
          !isSweeps && (
            <Select
              mt="3"
              placeholder="Select a Promo"
              defaultValue={
                !!banner
                  ? banner.treasure_hunt_promo_id
                  : values.treasure_hunt_promo_id
              }
              value={
                !!values.treasure_hunt_promo_id
                  ? values.treasure_hunt_promo_id
                  : banner?.treasure_hunt_promo_id
              }
              onChange={(e) =>
                setFieldValue('treasure_hunt_promo_id', e.target.value)
              }
            >
              {treasureHuntPromotions?.data.promotions.map((promotion) => (
                <option key={promotion.promo_id} value={promotion.promo_id}>
                  {promotion.name}
                </option>
              ))}

              {/* Add more options as needed */}
            </Select>
          )}

        {values.display === EDisplay.LoggedIn && (
          <>
            <BCCheckbox
              name="location"
              title={intl.formatMessage({
                id: 'promomanagerpage.banner.locations',
              })}
              values={AU_STATES}
              banner={banner?.location ?? []}
            />

            <BCCheckbox
              name="punter_category"
              title={intl.formatMessage({
                id: 'promomanagerpage.banner.punterCategory',
              })}
              values={punterCategoryList}
              banner={banner?.punter_category ?? []}
            />
          </>
        )}
        {banner && (
          <Flex gap={1}>
            <Button
              colorScheme="blue"
              variant="outline"
              isDisabled={!dirty}
              onClick={() =>
                navigate(`/promo-manager/banners/${banner?.platform ?? 'web'}`)
              }
            >
              Cancel
            </Button>

            <Button
              colorScheme="blue"
              variant="solid"
              type="submit"
              isLoading={isSubmitting}
              isDisabled={!dirty || isSubmitting}
            >
              Save changes
            </Button>
          </Flex>
        )}
      </BCGridItem>
    </BCGridWrapper>
  );
}
