import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Promotion name is required'),
  start_time: Yup.string().required('Start time is required'),
  expiry: Yup.string().required('Expiry is required'),
  audience_conditions: Yup.object().shape({
    punter_categories: Yup.array(),
    age_ranges: Yup.array(),
    address_locations: Yup.array(),
  }),
  parameters: Yup.object().shape({
    percentage: Yup.number()
      .required('Percentage is required')
      .min(0, 'Percentage must be at least 0'),
    max_amount: Yup.number()
      .required('Max stake is required')
      .min(1, 'Max stake must be at least $1'),
    number_of_races: Yup.number()
      .required('Number of treasure races is required')
      .min(1, 'Number of treasure races must be at least 1'),
  }),
  race_ids: Yup.array()
    .required('Race IDs are required')
    .min(1, 'At least one race is required'),
});

export default validationSchema;
